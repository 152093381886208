import React, { useState, useEffect } from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'utils/typography'

const STRIPE_KEY = process.env.GATSBY_STRIPE_KEY

const Subscribe = ({ planId, price, numberOfEng }) => {
  const [stripeLoaded, setStripeLoaded] = useState(false)

  useEffect(() => {
    if (stripeLoaded) {
      return
    }

    let stripe = window.Stripe(STRIPE_KEY, {
      betas: ['checkout_beta_4'],
    })

    let checkoutButton = document.getElementById('checkout-button')
    checkoutButton.addEventListener('click', async () => {
      // When the customer clicks on the button, redirect
      // them to Checkout.
      const result = await stripe.redirectToCheckout({
        items: [{ plan: planId, quantity: 1 }],

        // Note that it is not guaranteed your customers will be redirected to this
        // URL *100%* of the time, it's possible that they could e.g. close the
        // tab between form submission and the redirect.
        successUrl: `${ window.location.origin }/payments/success`,
        cancelUrl: `${ window.location.origin }/payments/canceled`,
      })

      if (result.error) {
        // If redirectToCheckout fails due to a browser or network
        // error, display the localized error message to your customer.
        let displayError = document.getElementById('error-message')
        displayError.textContent = result.error.message
      }
    })

    setStripeLoaded(true)
  }, [stripeLoaded])

  return (
    <Layout>
      <Container>
        <h1>Subscribe</h1>

        <p>
          Your plan is <b>${price}</b> per month for a team of {numberOfEng} engineers.
        </p>

        <StripeButton id="checkout-button" role="link" width={250}>
          Subscribe with Stripe
        </StripeButton>
        <div id="error-message" />
      </Container>
    </Layout>
  )
}

const StripeButton = styled.button`
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  ${ props => css`
    width: ${ rem(props.width) };
  ` }
  padding: 0 ${ rem(14) };
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: ${ rem(15) };
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  background-color: #6772e5;
  text-decoration: none;
  transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: ${ rem(10) };

  :hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Subscribe
